.container {
  display: flex;
  flex-direction: column;
}

.form-wrapper {
  padding: 16px 40px 40px;
  border-bottom: 1px solid var(--gray10_100);
}

.title {
  margin-bottom: 16px;
}

.form {
  margin-top: 24px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-wrapper {
  padding: 40px;
}

.info-title {
  margin-bottom: 8px;
}

.info-list {
  margin-bottom: 24px;
}

.info-item {
  margin-bottom: 4px;
  display: flex;
}

.info-item:last-child {
  margin-bottom: 0;
}

.bullet-wrapper {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 24px;
}

.free-report-title {
  margin-bottom: 12px;
}

.free-report-wrapper {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
}

.free-report-building {
  margin-right: 16px;
}

.status-bar-wrapper {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 16px;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.legal-docs-wrapper {
  margin-top: 40px;
}

.link {
  cursor: pointer;
  text-decoration: none;

  &,
  &:visited {
    color: var(--accent-main-primary);
  }

  &:hover,
  &:focus {
    color: var(--control-main-primary-hovered);
  }

  &:active {
    color: var(--control-main-primary-pressed);
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(16px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
