.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.success-wrapper {
  padding: 16px 40px 40px;
  border-bottom: 1px solid var(--gray10_100);
}

.success-title {
  margin-bottom: 16px;
}

.ads-wrapper {
  padding: 40px;
}

.ads-logo {
  margin-bottom: 24px;
  display: flex;
}

.ads-title {
  margin-bottom: 16px;
}

.ads-description {
  margin-bottom: 24px;
}
