.container {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  background-color: var(--decorative-theme-dark);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.container.error {
  background-color: var(--accent-negative-primary);
}

.spinner-wrapper {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 24px;
}
