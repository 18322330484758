.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 264px;
}

.icon-wrapper {
  margin: 0 0 24px;
}

.heading {
  margin: 0 0 8px;
}

.footer {
  display: flex;
  justify-content: center;
}
